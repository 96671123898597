import React from "react";
import { cn, f2u, DataObserveKey, VisualComponent } from "@siteimprove/fancylib";
import * as scss from "./progress-bar.scss";

export type ProgressbarProps = {
	value: number;
	total: number;
	tooltipContent?: string;
	/** If color is specified, it will take precedence over the colorRange property */
	color?: string;
	colorRange?: "cool" | "warm";
	reverseColorRange?: boolean;
	"aria-hidden"?: boolean;
} & DataObserveKey &
	VisualComponent;

export function ProgressBar(props: ProgressbarProps): JSX.Element {
	const {
		value,
		total,
		color,
		colorRange = "warm",
		reverseColorRange,
		"aria-hidden": ariaHidden,
		style,
		className,
	} = props;

	const generateProgressWidth = () => {
		if (total === 0) {
			return 0;
		} else if (value > total) {
			return 1;
		} else {
			return value / total;
		}
	};

	// used as an inline-style
	const progressBarWidth = generateProgressWidth();

	return (
		<div style={style} className={cn(scss.horizontalProgressBar, className)}>
			<div className={scss.progressContainer}>
				<span className={scss.progressWrapper}>
					<span
						role="progressbar"
						className={
							color
								? scss.singleColor
								: reverseColorRange
								? colorRange === "cool"
									? scss.heatRangeCoolReverse
									: scss.heatRangeWarmReverse
								: colorRange === "cool"
								? scss.heatRangeCool
								: scss.heatRangeWarm
						}
						style={{ backgroundColor: color }}
						ref={(el) => {
							if (el) {
								el.style.setProperty(
									"animation-iteration-count",
									progressBarWidth.toString(),
									"important"
								);
							}
						}}
						aria-hidden={ariaHidden}
						aria-valuemin={f2u(!ariaHidden && 0)}
						aria-valuenow={f2u(!ariaHidden && value)}
						aria-valuemax={f2u(!ariaHidden && total)}
						aria-valuetext={f2u(!ariaHidden && (total == 100 ? value + "%" : value + "/" + total))}
					></span>
				</span>
			</div>
		</div>
	);
}
