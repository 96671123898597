const cache = new Map<string, Intl.NumberFormat>();
export function createNumberFormatter(
	locale: string,
	options?: Intl.NumberFormatOptions
): Intl.NumberFormat {
	const key = JSON.stringify([locale, options]);

	const cacheHit = cache.get(key);
	if (cacheHit) return cacheHit;

	const value = new Intl.NumberFormat(locale, options);
	cache.set(key, value);
	return value;
}
