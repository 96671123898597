import * as React from "react";
import { SrOnly, useFormattingLanguage } from "@siteimprove/fancylib";
import { useLabTranslations } from "../../../translations/translations";
import { createNumberFormatter } from "../../../utils/number-formatter";

const defaultCurrency = "USD";
const defaultCurrencyToken = "\\$";
const currencyReplacement = new RegExp(`${defaultCurrency}|${defaultCurrencyToken}`, "i");
const defaultDigits = 2;

interface BaseProps {
	number: number | null;
	locale?: string;
	className?: string;
}

interface PercentProps {
	/** Numbers are expected to be between 0 and 1. Higher numbers will give higher than 100% values. */
	format: "percent";
	digits?: number;
}

interface NumberProps {
	format?: "number" | "percent";
	digits?: number;
	alwaysShowDigits?: boolean;
}

interface CurrencyProps {
	format: "currency";
	currency?: string;
}

type FormattedNumberProps = BaseProps & (NumberProps | CurrencyProps | PercentProps);

export function FormattedNumber(props: FormattedNumberProps): JSX.Element {
	const { number, className } = props;
	const i18nLab = useLabTranslations();
	const locale = useFormattingLanguage();
	const value = toFormattedNumberString({ ...props, locale: props.locale ?? locale });

	return number !== null ? (
		<span data-component="formatted-number" className={className}>
			{value}
		</span>
	) : (
		<span data-component="formatted-number" className={className}>
			<span aria-hidden="true">{value}</span>
			<SrOnly>{i18nLab.numberMissing}</SrOnly>
		</span>
	);
}

function toNumberString(
	number: number,
	locale: string,
	options?: Intl.NumberFormatOptions
): string {
	return createNumberFormatter(locale, options).format(number);
}

type ToFormattedNumberProps = FormattedNumberProps & { locale: string };

export function toFormattedNumberString(props: ToFormattedNumberProps): string {
	const { number, locale } = props;

	if (number === null || number === undefined) {
		return "-";
	}

	switch (props.format) {
		case "number":
			return toNumberString(number, locale, {
				minimumFractionDigits: props.alwaysShowDigits ? props.digits : undefined,
				maximumFractionDigits: props.digits !== undefined ? props.digits : defaultDigits,
			});

		case "currency":
			return toNumberString(number, locale, {
				style: "currency",
				currency: defaultCurrency,
			}).replace(currencyReplacement, props.currency || defaultCurrency);

		case "percent":
			return toNumberString(number, locale, {
				style: "percent",
				minimumFractionDigits: props.digits || defaultDigits,
				maximumFractionDigits: props.digits || defaultDigits,
			});

		default:
			return toNumberString(number, locale);
	}
}
