import React from "react";
import { Link as GatsbyLink } from "gatsby";
import { Card, Ol, Paragraph, TextContainer, Tooltip, Ul } from "@siteimprove/fancylib";
import {
	Knobs,
	Example,
	DocPageMeta,
	InlineMessage,
	ContentSection,
	HeaderSection,
	ImportExample,
	Header,
	Code,
} from "../../../../../src/docs";

import { LabWarning } from "../../../../../src/docs/docs-lab-warning";
import { FormattedNumber } from "../formatted-number/formatted-number";
import { ProgressBar } from "../../data-visualization/progress-bar/progress-bar";
import { Highlight } from "./highlight";

export const Meta: DocPageMeta = {
	category: "Text",
	title: "Highlight",
	notepad: "https://hackmd.io/@Ulcvl-fvShmIdi46-hA8JQ/Hyy5N4txY",
};

export default (): JSX.Element => (
	<>
		<HeaderSection
			title="Highlight"
			subTitle="Draw the attention of users to important data and metrics."
		/>
		<ContentSection>
			<TextContainer article>
				<LabWarning />
				<ImportExample lab component={"Highlight"} />
				<Header.H2>Examples</Header.H2>
				<Paragraph>
					The <Code>Highlight</Code> component consists of four namespaced components:
				</Paragraph>
				<Ol
					items={[
						<>
							<Code>Highlight.Layout</Code> takes care of sizing, spacing, and responsive behavior.
						</>,
						<>
							<Code>Highlight.Item</Code> contains the data that needs to be highlighted.
						</>,
						<>
							<Code>Highlight.Title</Code> serves as the subtle heading of a highlight item.
						</>,
						<>
							<Code>Highlight.Value</Code> presents a string or number in a visually salient manner.
						</>,
					]}
				/>

				<Header.H3>Default</Header.H3>
				<Paragraph>
					This is the default implementation of the <Code>Highlight</Code> component. Things to take
					note of:
				</Paragraph>
				<Ul
					items={[
						<>
							The component can be used inside any container, but is mainly intended for use inside
							of a <GatsbyLink to="/lib/components/Structure/Card">Card</GatsbyLink>.
						</>,
						<>
							An <Code>aria-label</Code> is required. It should describe what the collection of
							highlight items concerns.
						</>,
						<>
							Although not required, you'll usually want to wrap a number in a{" "}
							<GatsbyLink to="/lab/components/Text/Formatted Number">FormattedNumber</GatsbyLink>{" "}
							component.
						</>,
					]}
				/>
				<Example fn={defaultExample} />
				<Header.H3>Heading level</Header.H3>
				<Paragraph>
					By default, the <Code>Highlight.Title</Code> component outputs an <Code>h3</Code> level
					heading. That's because the component will often be nested inside of a{" "}
					<GatsbyLink to="/lib/components/Structure/Card">Card</GatsbyLink> with an <Code>h2</Code>{" "}
					level heading. When this is not the case, the <Code>headingLevel</Code> property can be
					used to output a different heading level.
				</Paragraph>
				<Example fn={headingLevelExample} />
				<Header.H3>Extra content</Header.H3>
				<Paragraph>
					A <Code>Highlight.Item</Code> component can contain more than a{" "}
					<Code>Highlight.Title</Code> and <Code>Highlight.Value</Code>. Simply add the extra
					content as another child. Correct spacing will automatically be applied.
				</Paragraph>
				<Example fn={extraContentExample} />
				<Header.H3>No padding</Header.H3>
				<Paragraph>
					Use the <Code>noPadding</Code> property to remove a <Code>Highlight.Item</Code>{" "}
					component's padding.
				</Paragraph>
				<Example fn={noPaddingExample} />
				<Header.H3>No gap</Header.H3>
				<Paragraph>
					Use the <Code>noGap</Code> property to remove the space between the title, value, and
					other children elements nested inside a <Code>Highlight.Item</Code> component.
				</Paragraph>
				<Example fn={noGapExample} />
				<Header.H3>Compact</Header.H3>
				<Paragraph>
					Use the <Code>compact</Code> property of the <Code>Highlight.Layout</Code> compontent to
					render a smaller, bordered Highlight component. This version is intended for use inside
					containers other than a <GatsbyLink to="/lib/components/Structure/Card">Card</GatsbyLink>,
					such as a <GatsbyLink to="/lab/components/Overlay/Side panel">Side Panel</GatsbyLink> or{" "}
					<GatsbyLink to="/lab/components/Overlay/Modal">Modal</GatsbyLink>.
				</Paragraph>
				<Example fn={compactExample} />
				<Header.H2>Properties</Header.H2>
				<Header.H3>Highlight.Layout</Header.H3>
				<Knobs
					component={Highlight.Layout}
					initialProps={{
						children: (
							<>
								<Highlight.Item>
									<Highlight.Title>Number</Highlight.Title>
									<Highlight.Value>
										<FormattedNumber number={20492} format="number" />
									</Highlight.Value>
								</Highlight.Item>
								<Highlight.Item>
									<Highlight.Title>Percentage</Highlight.Title>
									<Highlight.Value>
										<FormattedNumber number={0.5484} format="percent" />
									</Highlight.Value>
								</Highlight.Item>
								<Highlight.Item>
									<Highlight.Title>Currency</Highlight.Title>
									<Highlight.Value>
										<FormattedNumber number={1234567} format="currency" />
									</Highlight.Value>
								</Highlight.Item>
								<Highlight.Item>
									<Highlight.Title>Empty</Highlight.Title>
									<Highlight.Value>
										<FormattedNumber number={null} />
									</Highlight.Value>
								</Highlight.Item>
								<Highlight.Item>
									<Highlight.Title>
										<Tooltip variant={{ type: "text" }} content="This title has a tooltip.">
											Tooltip
										</Tooltip>
									</Highlight.Title>
									<Highlight.Value>Yes</Highlight.Value>
								</Highlight.Item>
							</>
						),
						"aria-label": "hello",
					}}
				/>
				<Header.H3>Highlight.Item</Header.H3>
				<Knobs
					component={Highlight.Item}
					initialProps={{
						children: (
							<>
								<Highlight.Title>Number</Highlight.Title>
								<Highlight.Value>
									<FormattedNumber number={20492} format="number" />
								</Highlight.Value>
							</>
						),
					}}
				/>
				<Header.H3>Highlight.Title</Header.H3>
				<Knobs
					component={Highlight.Title}
					initialProps={{
						children: <Highlight.Title>Number</Highlight.Title>,
					}}
				/>
				<Header.H3>Highlight.Value</Header.H3>
				<Knobs
					component={Highlight.Value}
					initialProps={{
						children: (
							<Highlight.Value>
								<FormattedNumber number={20492} format="number" />
							</Highlight.Value>
						),
					}}
				/>
				<Header.H2>Guidelines</Header.H2>
				<Header.H3>Best practices</Header.H3>
				<InlineMessage variant="best-practices">
					<Ul
						items={[
							<>
								Use the <Code>Highlight</Code> component for data and metrics that are clearly
								related.
							</>,
							<>
								Use the default version inside any type of container, such as a{" "}
								<GatsbyLink to="/lib/components/Structure/Card">Card</GatsbyLink>,{" "}
								<GatsbyLink to="/lab/components/Overlay/Side panel">Side Panel</GatsbyLink> or{" "}
								<GatsbyLink to="/lab/components/Overlay/Modal">Modal</GatsbyLink>.
							</>,
							<>
								Use the <Code>compact</Code> version inside any type of container, other than a{" "}
								<GatsbyLink to="/lib/components/Structure/Card">Card</GatsbyLink>.
							</>,
							<>
								Let the <Code>Highlight</Code> component take up 100% of its container’s width.
							</>,
							<>
								Add spacing around the{" "}
								<GatsbyLink to="/lab/components/Text/Highlight#compact">compact</GatsbyLink>{" "}
								version, so it doesn’t take up 100% of it's container's width.
							</>,
							<>
								Try to limit the optional{" "}
								<GatsbyLink to="/lab/components/Text/Highlight#extra-content">
									extra content
								</GatsbyLink>{" "}
								to a single element.
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Do not use when</Header.H3>
				<InlineMessage variant="do-not-use-when">
					<Paragraph>There are no do-not-use-when guidelines for this component.</Paragraph>
				</InlineMessage>
				<Header.H3>Accessibility</Header.H3>
				<InlineMessage variant="accessibility">
					<Paragraph>
						Make sure the the title has the{" "}
						<GatsbyLink to="/lib/components/Text/Heading#accessibility">
							correct heading level
						</GatsbyLink>
						. (E.g., <Code>&lt;h2&gt;</Code>, <Code>&lt;h3&gt;</Code>, <Code>&lt;h4&gt;</Code>.) You
						can use the <Code>headingLevel</Code> property of the <Code>Highlight.Title</Code>{" "}
						component to adjust it.
					</Paragraph>
				</InlineMessage>
				<Paragraph>
					Explore detailed guidelines for this component:{" "}
					<a href="https://siteimprove-wgs.atlassian.net/wiki/x/4wQNeQ">
						Accessibility Specifications
					</a>
				</Paragraph>
				<Header.H3>Writing</Header.H3>
				<InlineMessage variant="writing">
					<Paragraph>
						Keep both the title and value as short as possible. For the title, remember to follow
						the{" "}
						<GatsbyLink to="/lib/components/Text/Heading#writing">
							Heading component’s writing guidelines
						</GatsbyLink>
						.
					</Paragraph>
				</InlineMessage>
			</TextContainer>
		</ContentSection>
	</>
);

const defaultExample = () => (
	<Card>
		<Highlight.Layout aria-label="Highlights">
			<Highlight.Item>
				<Highlight.Title>Number</Highlight.Title>
				<Highlight.Value>
					<FormattedNumber number={20492} format="number" />
				</Highlight.Value>
			</Highlight.Item>
			<Highlight.Item>
				<Highlight.Title>Percentage</Highlight.Title>
				<Highlight.Value>
					<FormattedNumber number={0.5484} format="percent" />
				</Highlight.Value>
			</Highlight.Item>
			<Highlight.Item>
				<Highlight.Title>Currency</Highlight.Title>
				<Highlight.Value>
					<FormattedNumber number={1234567} format="currency" />
				</Highlight.Value>
			</Highlight.Item>
			<Highlight.Item>
				<Highlight.Title>Empty</Highlight.Title>
				<Highlight.Value>
					<FormattedNumber number={null} />
				</Highlight.Value>
			</Highlight.Item>
			<Highlight.Item>
				<Highlight.Title>
					<Tooltip variant={{ type: "text" }} content="This title has a tooltip.">
						Tooltip
					</Tooltip>
				</Highlight.Title>
				<Highlight.Value>Yes</Highlight.Value>
			</Highlight.Item>
		</Highlight.Layout>
	</Card>
);

const headingLevelExample = () => (
	<Card>
		<Highlight.Layout aria-label="Highlights">
			<Highlight.Item>
				<Highlight.Title headingLevel="h5">Number</Highlight.Title>
				<Highlight.Value>
					<FormattedNumber number={20492} format="number" />
				</Highlight.Value>
			</Highlight.Item>
			<Highlight.Item>
				<Highlight.Title headingLevel="h5">Percentage</Highlight.Title>
				<Highlight.Value>
					<FormattedNumber number={0.5484} format="percent" />
				</Highlight.Value>
			</Highlight.Item>
			<Highlight.Item>
				<Highlight.Title headingLevel="h5">Currency</Highlight.Title>
				<Highlight.Value>
					<FormattedNumber number={1234567} format="currency" />
				</Highlight.Value>
			</Highlight.Item>
			<Highlight.Item>
				<Highlight.Title headingLevel="h5">Empty</Highlight.Title>
				<Highlight.Value>
					<FormattedNumber number={null} />
				</Highlight.Value>
			</Highlight.Item>
			<Highlight.Item>
				<Highlight.Title headingLevel="h5">
					<Tooltip variant={{ type: "text" }} content="This title has a tooltip.">
						Tooltip
					</Tooltip>
				</Highlight.Title>
				<Highlight.Value>Yes</Highlight.Value>
			</Highlight.Item>
		</Highlight.Layout>
	</Card>
);

const extraContentExample = () => (
	<Card>
		<Highlight.Layout aria-label="Highlights">
			<Highlight.Item>
				<Highlight.Title>Number</Highlight.Title>
				<Highlight.Value>
					<FormattedNumber number={42} format="number" />
				</Highlight.Value>
				<Paragraph tone="subtle">The answer to life, the universe, and everything.</Paragraph>
			</Highlight.Item>
			<Highlight.Item>
				<Highlight.Title>Progress</Highlight.Title>
				<Highlight.Value>
					<FormattedNumber number={0.5484} format="percent" />
				</Highlight.Value>
				<ProgressBar value={54.84} total={100} />
			</Highlight.Item>
		</Highlight.Layout>
	</Card>
);

const noPaddingExample = () => (
	<Card>
		<Highlight.Layout aria-label="Highlights">
			<Highlight.Item>
				<Highlight.Title>Padding</Highlight.Title>
				<Highlight.Value>Yes</Highlight.Value>
			</Highlight.Item>
			<Highlight.Item noPadding>
				<Highlight.Title>Padding</Highlight.Title>
				<Highlight.Value>No</Highlight.Value>
			</Highlight.Item>
		</Highlight.Layout>
	</Card>
);

const noGapExample = () => (
	<Card>
		<Highlight.Layout aria-label="Highlights">
			<Highlight.Item>
				<Highlight.Title>Gap</Highlight.Title>
				<Highlight.Value>Yes</Highlight.Value>
			</Highlight.Item>
			<Highlight.Item noGap>
				<Highlight.Title>Gap</Highlight.Title>
				<Highlight.Value>No</Highlight.Value>
			</Highlight.Item>
		</Highlight.Layout>
	</Card>
);

const compactExample = () => (
	<Highlight.Layout aria-label="Highlights" compact>
		<Highlight.Item>
			<Highlight.Title headingLevel="h5">Number</Highlight.Title>
			<Highlight.Value>
				<FormattedNumber number={20492} format="number" />
			</Highlight.Value>
		</Highlight.Item>
		<Highlight.Item>
			<Highlight.Title>Percentage</Highlight.Title>
			<Highlight.Value>
				<FormattedNumber number={0.5484} format="percent" />
			</Highlight.Value>
		</Highlight.Item>
		<Highlight.Item>
			<Highlight.Title>Currency</Highlight.Title>
			<Highlight.Value>
				<FormattedNumber number={1234567} format="currency" />
			</Highlight.Value>
		</Highlight.Item>
		<Highlight.Item>
			<Highlight.Title>Empty</Highlight.Title>
			<Highlight.Value>
				<FormattedNumber number={null} />
			</Highlight.Value>
		</Highlight.Item>
		<Highlight.Item>
			<Highlight.Title>
				<Tooltip variant={{ type: "text" }} content="This title has a tooltip.">
					Tooltip
				</Tooltip>
			</Highlight.Title>
			<Highlight.Value>Yes</Highlight.Value>
		</Highlight.Item>
	</Highlight.Layout>
);
