import React, { ReactNode } from "react";
import { cn, DataObserveKey, InlineText, VisualComponent } from "@siteimprove/fancylib";
import * as scss from "./highlight.scss";

type HighlightLayoutProps = {
	/** The content (highlight items) of the highlight section */
	children: ReactNode;
	/** Labels the group of highlights.  */
	"aria-label": string;
	/** Adds rounded border and reduces padding, spacing, and font-size  */
	compact?: boolean;
} & DataObserveKey &
	VisualComponent;

function HighlightLayout(props: HighlightLayoutProps): JSX.Element {
	const { children, className, compact, style } = props;

	return (
		<section
			data-component="highlight.layout"
			data-observe-key={props["data-observe-key"]}
			className={cn(scss.layout, className, compact && scss.compact)}
			style={style}
			aria-label={props["aria-label"]}
		>
			{children}
		</section>
	);
}

export type HighlightItemProps = {
	/** The content of the highlight */
	children: ReactNode;
	/** Removes the highlight's padding */
	noPadding?: boolean;
	/** Removes the highlight's gap */
	noGap?: boolean;
} & VisualComponent;

function HighlightItem(props: HighlightItemProps): JSX.Element {
	const { children, noPadding, noGap, className, style } = props;

	return (
		<div
			data-component="highlight.item"
			className={cn(scss.highlight, className, noPadding && scss.noPadding, noGap && scss.noGap)}
			style={style}
		>
			{children}
		</div>
	);
}

type HighlightTitleProps = {
	/** The content (label) of the title */
	children: ReactNode;
	/** The heading level of the title (defaults to h3) */
	headingLevel?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
} & VisualComponent;

function HighlightTitle(props: HighlightTitleProps): JSX.Element {
	const { children, headingLevel = "h3", className } = props;

	return React.createElement(
		headingLevel,
		{ className: cn(scss.title, className) },
		<InlineText>{children}</InlineText>
	);
}

type HighlightValueProps = {
	/** The content (label) of the value */
	children: ReactNode;
} & VisualComponent;

function HighlightValue(props: HighlightValueProps): JSX.Element {
	const { children, className, style } = props;

	return (
		<div data-component="highlight.value" className={cn(scss.value, className)} style={style}>
			{children}
		</div>
	);
}

export const Highlight = {
	Layout: HighlightLayout,
	Item: HighlightItem,
	Title: HighlightTitle,
	Value: HighlightValue,
};

HighlightLayout.displayName = "Highlight.Layout";
HighlightItem.displayName = "Highlight.Item";
HighlightTitle.displayName = "Highlight.Title";
HighlightValue.displayName = "Highlight.Value";
